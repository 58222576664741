
// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $main-link;

  &:hover {
    color: $main-link-hover;
  }
}

a,
button,
.dd-tags a,
.dd-title-list,
.dd-slide-content-small .dd-title {
  cursor: pointer;
  @include fade;
}

a[href^="tel"]{
  color:inherit;
  text-decoration:none;
}

html, body {
  height: 100%;
  display: initial;
}

body {
  font-family: $font;
  font-size: 14px;
  color: $gray;
}

img {
  max-width: 100%;
  border: none;
  vertical-align: top;
}

figcaption {
  background-color: #e6e6e6;
  font-size: 12px;
}

img,
input,
textarea,
select,
a,
button {
  border: none;
  background-color: $transparent;
}

img:focus,
input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus {
  outline: none;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 100;
}

#dd-wrapper {
  min-height: 100%;
  position: relative;
}

.dd-wrapper {
  padding-top: 60px;
  padding-bottom: $footer-height;
}

form {
  width: 100%;
}

.main {
  @extend .dd-normal;

  .box {
    @extend .dd-normal-box;
  }
}

.bx-wrapper {
  width: 100%;
  max-width: 100% !important;
  box-shadow: none;
  margin: 0 auto;
  border: 0;
  text-align: center;
  @extend .dd-sizing;

  .bx-viewport {
    margin: 0 auto;
    display: inline-block;
  }
}

.bx-loading {
  background: url('../images/bx_loader.gif') center center no-repeat #ffffff !important;
}

//---------------//----------------//

//HEADER
//====================
//Logo
.dd-logo {
  padding-right: 22px;

  @media screen and (max-width: $large) {
    margin: 0 auto;
    padding: 0;
    max-width: 320px;
    display: block;
    margin-bottom: 50px;
  }
}

.dd-logo-2 {
  width: 238px;
  vertical-align: top;
  display: none;
}

.dd-banner-initial {
  max-height: 150px;
  overflow: hidden;
}

//Slider header
.dd-slide-content-small {
  @include calc('width', '100% - 443px');
  padding-right: 11px;
  margin: 17px 0;
  visibility: hidden;

  @media screen and (max-width: $large) {
    display: none;
  }

  .dd-calc-info {
    @include calc('width', '100% - 195px');
    padding: 0 40px 0 21px;
  }

  .dd-title {
    font-size: 18px;
    margin-bottom: 11px;
    color: $red;

    &:hover {
      color: $gray;
    }
  }

  .bx-wrapper {
    text-align: left;

    .bx-pager {
      width: auto;
      position: absolute;
      bottom: inherit;
      right: -11px;
      padding-top: 0;
      top: 50%;
      margin-top: -31px;
    }

    .bx-pager-item {
      display: block;
    }

    .bx-pager.bx-default-pager a {
      background-color: $gray--5;
      width: 8px;
      height: 8px;
      margin: 6px 0;
    }

    .bx-pager.bx-default-pager a.active {
      background-color: $gray--2;
    }

    .bx-pager.bx-default-pager a:hover {
      background-color: $gray--2;
    }
  }
}

//Menu
.dd-section-menu {
  position: relative;
  z-index: 9999;
  height: 50px;
}

.dd-section-menu .dd-icon-search {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -17px;
}

.dd-wrap-menu {
  @include degrade('right', $red--4, $red--5);
}

.dd-container-menu {
  @include calc('width', '100% - 47px');
  vertical-align: top;

  @media screen and (max-width: 850px) {
    display: none;
  }
}

//Normal menu
.dd-menu {
  padding-left: 22px;
  min-height: 48px;

  @media screen and (max-width: 850px) {
    display: none;
  }

  a {
    font-size: 15px;
    color: $white;
    padding: 13px 21px 17px 21px;
    display: block;
    letter-spacing: 0.1px;
    transition: none;

    @media screen and (max-width: $large) {
      padding: 13px 12px 17px 12px;
    }

    @media screen and (max-width: 950px) {
      padding: 13px 11px 17px 11px;
      font-size: 13px;
    }
  }

  li {
    position: relative;
  }

  li .dd-sub {
    position: absolute;
    left: 0;
    top: 100%;
    background: $red--3;
    display: none;
    width: 135%;
    list-style-type: none;
  }

  li .dd-sub a {
    padding: 7px;
    font-size: 13px;
  }

  .dd-active > a,
  li:hover > a{
    background-color: $red--5;
    color: $white;
  }

  .dd-active::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $red--7;
  }
}

//Fixed menu
.dd-menu-fixed {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;

  .dd-normal-box {
    height: 70px;

    @media screen and (max-width: $large) {
      height: inherit;
    }
  }

  .dd-logo-2 {
    display: inline-block;
    @include fade;

    @media screen and (max-width: $large) {
      display: none;
    }
  }

  .dd-container-menu {
    max-width: 742px;
  }

  .dd-menu a {
    font-size: 13px;
    padding: 26px 11px 26px 11px;

    @media screen and (max-width: 1040px) {
      padding: 26px 10px 26px 10px;
    }
  }

  .dd-responsive-menu {
    @media screen and (max-width: 850px) {
      display: block;
    }
  }

  .dd-icon-search {
    right: -10px;

    @media screen and (max-width: 1040px) {
      right: 0;
    }
  }

  .dd-menu li .dd-sub {
    width: 185%;
  }
}

//Responsive menu
.dd-responsive-menu {
  display: none;
  width: 100%;
  left: 0;
  top: 0;

  @media screen and (max-width: 850px) {
    display: block;
  }

  .slicknav_btn {
    background-color: transparent;
    float: left;
    margin: 0;
    padding: 18px;
  }

  .slicknav_menu {
    background-color: transparent;
    padding: 0;
  }

  .slicknav_nav {
    width: 100%;
    background: #910000;
    position: absolute;
    top: 100%;
  }

  .slicknav_nav a {
    text-align: center;
  }

  .slicknav_icon {
    width: 26px;
  }

  .slicknav_icon-bar {
    width: 100%;
    height: 3px;
  }
}


//end HEADER
//====================

//---------------//----------------//

//FOOTER
//====================
.dd-footer {
  width: 100%;
  background-color: $footer-bg;
  color: $white;
  line-height: 26px;
  padding: 19px 0;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: $large) {
    font-size: 12px;
  }

  @media screen and (max-width: $tablet) {
    font-size: 11px;
  }
}
//end FOOTER
//====================

//---------------//----------------//

//FORMS
//====================
p.dd-error {
  width: 100%;
  font-size: 13px;
  margin: 8px 0;
}

.dd-box-radio {
  input[type="radio"] {
    display:none;
  }

  .dd-radio-custom {
    background-image: $sprite;
    background-position: -66px -15px;
    background-repeat: no-repeat;
    width: 11px;
    height: 12px;
    display: inline-block;
  }

  input[type="radio"]:checked + label .dd-radio-custom {
    background-position: -66px -4px;
  }

  .dd-label {
    color: $gray--2;
    font-size: 14px;
    cursor: pointer;
  }
}
//end FORMS
//====================

//---------------//----------------//

//LINE FORM SEARCH
//====================
.dd-line-search {
  background-color: $gray--12;
  width: 100%;
  margin: 0 auto;
  display: block;
  @include border-radius(9px);

  @media screen and (max-width: $mobile) {
    background-color: transparent;
  }

  .dd-box-field {
    width: 90%;
    height: 32px;

    @media screen and (max-width: $large) {
      width: 85%;
    }

    @media screen and (max-width: $tablet) {
      width: 80%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .dd-field {
    background-color: $white;
    width: 100%;
    height: 32px;
    font-size: 12px;
    border: 1px solid $gray--12;
    vertical-align: top;
    padding: 0 10px;
    color: $gray--2;
    @include border-radius(9px);
  }

  .dd-btn-submit {
    width: 10%;
    height: 32px;
    background-color: $gray--12;
    font-size: 14px;
    color: $white;
    text-transform: uppercase;
    padding: 7px 0 8px 0;
    vertical-align: top;
    text-align: center;
    transition: none;
    @include border-radius(9px);

    @media screen and (max-width: $large) {
      width: 15%;
    }

    @media screen and (max-width: $tablet) {
      width: 20%;
      font-size: 13px;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .dd-arrow-down {
    right: 8px;
  }
}

.dd-line-search-cat {
  border-bottom-width: 3px;
  border-bottom-color: $red;
  border-bottom-style: solid;

  .dd-label {
    font-size: 14px;
    color: $red;
    text-transform: uppercase;
    margin: 0 25px 0 0;

    @media screen and (max-width: $tablet) {
      margin: 0 0 20px 0;
    }
  }

  .dd-cat-list {
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .dd-cat-sel {
    display: none;

    @media screen and (max-width: $tablet) {
      display: block;
    }
  }

  .dd-box-order-list {
    @media screen and (max-width: $tablet) {
      margin-bottom: 10px;
    }
  }

  .dd-cat-list li {
    margin-right: 22px;
    vertical-align: middle;

    @media screen and (max-width: $large) {
      margin-right: 4px;
    }
  }

  .dd-cat-list li:last-child {
    margin-right: 0;
  }

  .dd-cat-list a {
    font-size: 14px;
    color: $gray;
    text-transform: uppercase;
    padding: 7px 15px;
    display: inline-block;

    @media screen and (max-width: $large) {
      font-size: 12px;
      padding: 7px 6px;
    }
  }

  .dd-cat-list a.dd-active {
    font-family: $open-sans-bold;
    @include degrade('bottom', $white, $gray--13);
  }
}

.dd-box-filter {
  width: 100%;
  padding: 8px 0;

  .dd-text {
    color: $gray--3;
  }
}

.dd-box-order-list {
  border-left-width: 1px;
  border-left-color: $gray--5;
  border-left-style: solid;
  padding: 7px 0 7px 12px;
  right: 0;
  bottom: 0;

  @media screen and (max-width: $tablet) {
    width: 100% !important;
    position: relative !important;
    border-left: 0;
    padding: 10px 20px;
    background-color: $gray--4;
    right: 0;
    left: 0;
  }
}

.dd-box-show-list {
  width: 70px;
  height: 20px;
  right: 30px;
  top: 50%;
  margin-top: -10px;

  @media screen and (max-width: $tablet) {
    right: 0;
  }
}

.dd-order-select {
  height: 53px;

  option {
    background-color: $gray--5;
    color: $gray;
  }
}

.dd-form-search-know {
  width: 360px;

  @media screen and (max-width: $tablet) {
    width: 100%;
    margin: 11px 0px;
  }

  .dd-box-field {
    width: 85%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .dd-btn-submit {
    width: 15%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}

.dd-form-box {
  display: inline-block;

  .dd-box-field {
    width: 76%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .dd-btn-submit {
    width: 24%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}

.form-search-home .dd-line-search {
  margin-bottom: 15px;
}

.dd-form-search-news {
  .dd-box-order-list {
    width: 145px;
    bottom: 4px;
    right: 30px;
  }

  .dd-box-filter {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $gray--5;
  }
}

.dd-form-search-ruling {
  .dd-box-field {
    width: 75%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .dd-btn-submit {
    width: 25%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .dd-icon-search--2 {
    margin-top: -4px;
    margin-left: 3px;
  }

  .dd-box-order-list {
    width: 105px;
    margin-right: 30px;

    @media screen and (max-width: $tablet) {
      margin-right: 0;
    }
  }
}


.dd-form-search, .dd-form-thesis {
  .dd-box-order-list {
    width: 145px;
    border-left: 0;
    float: right;
  }
}
//end LINE FORM SEARCH
//====================

//---------------//----------------//

//STRUCTURE
//====================
.dd-wsidebar {
  width: 71%;
  vertical-align: top;
  font-size: 14px;

  @media screen and (max-width: $large) {
    width: 100%;
  }
}

.dd-bside {
  width: 29%;
  vertical-align: top;
  padding-left: 40px;

  @media screen and (max-width: $large) {
    display: none;
  }

  .dd-title-page {
    font-size: 16px;
    letter-spacing: 0.7px;
    border-width: 2px;
    padding-bottom: 3px;
    margin-bottom: 0;
  }

  .dd-radio {
    margin-bottom: 73px;
  }

  section {
    display: inline-block;
    margin-bottom: 50px;
  }
}

.dd-box-search {
  width: 100%;
  background-color: $gray--6;
  padding: 18px;
  @include border-radius(7px);

  .dd-line-search {
    width: 46%;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;


    @media screen and (max-width: $large) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .dd-container-radios {
    width: 54%;
    vertical-align: middle;
    padding-left: 25px;

    @media screen and (max-width: $large) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.dd-box-radio {
  vertical-align: top;
}

.dd-col-radios-4 {

  .dd-box-radio:nth-child(4n+1){
    width: 16%;
  }

  .dd-box-radio:nth-child(4n+2){
    width: 29%;
  }

  .dd-box-radio:nth-child(4n+3){
    width: 20%;
  }

  .dd-box-radio:nth-child(4n+4){
    width: 35%;
  }

  .dd-box-radio {
    margin-bottom: 11px;
  }

  .dd-box-radio {
    @media screen and(max-width: $tablet) {
      width: 33.333% !important;
    }

    @media screen and(max-width: $mobile) {
      width: 100% !important;
    }
  }
}

.dd-col-radios-ruling-4 {

  .dd-box-radio {
    width: 25%;
  }

  .dd-box-radio {
    margin-bottom: 11px;
  }

  .dd-box-radio {
    @media screen and(max-width: $tablet) {
      width: 33.333% !important;
    }

    @media screen and(max-width: $mobile) {
      width: 100% !important;
    }
  }
}

.dd-col-radios-3 .dd-box-radio {
  width: 33.333%;
  padding-right: 5px;
  margin-bottom: 11px;

  @media screen and(max-width: $mobile) {
    width: 100%;
  }

  &:nth-last-child(-n+3) {
    @media screen and (min-width: $mobile + 1) {
      margin-bottom: 0;
    }
  }
}

.dd-box-video {
  max-width: 700px;
  margin: 0 auto;

  .dd-video {
    width: 100%;
    max-height: 510px;
    height: 62vw;
  }
}

.dd-box-half-left {
  width: 100%;
  vertical-align: top;
}

.dd-box-half-left.dd-calc-info {
  width: 49%;

  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

.dd-box-half-right {
  width: 51%;
  padding-left: 30px;
  vertical-align: top;

  @media screen and (max-width: $tablet) {
    width: 100%;
    padding-left: 0;
  }
}

.dd-image-cover-news {
  max-width: 650px;
}

.dd-img-cover-production {
  max-width: 480px;
  display: inline-block;
  float: right;

  @media screen and (max-width: $tablet) {
    float: none;
    margin: 0 auto;
    display: block;
  }
}

.dd-cover-default {
  display: inline-block;
  float: left;

  @media screen and (max-width: $tablet) {
    float: none;
    margin: 0 auto;
    display: block;
  }
}

.dd-content-box-cover {
  @media screen and (max-width: $tablet) {
    display: inline-block;
  }
}

.dd-img-cover-know {
  max-width: 480px;
  padding: 0 40px 80px 0;

  @media screen and (max-width: $tablet) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.dd-img-cover-ruling {
  max-width: 290px;
  padding: 0 40px 40px 0;

  @media screen and (max-width: $tablet) {
    margin-bottom: 40px;
    padding: 0;
  }
}

.dd-share-right {
  width: 100%;
  display: inline-block;

  .dd-box-share {
    float: right;
  }
}

.dd-title-wform {
  border-bottom-width: 2px;
  border-bottom-color: $red;
  border-bottom-style: solid;
  padding-bottom: 4px;
}

.dd-title-wform .dd-title-section {
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
  padding-left: 20px;
  margin-right: 15px;

  @media screen and (max-width: $tablet) {
    padding-left: 0;
  }
}

.dd-box-slider-exposition {
  max-width: 662px;
  margin: 0 auto;
}

.dd-header-resolutions {
  .dd-col:first-of-type {
    width: 52%;
    padding-right: 40px;

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 35px;
    }
  }

  .dd-col:last-of-type {
    width: 48%;

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  .dd-col.dd-w-full {
    width: 100%;
    padding: 0;
  }
}
//end STRUCTURE
//====================

//---------------//----------------//

//TAGS
//====================
.dd-box-tags {
  .dd-title-tags {
    font-size: 13px;
    display: block;
    margin-bottom: 16px;
  }

  .dd-tags a {
    background: $gray--5;
    font-size: 14px;
    color: $gray;
    padding: 6px 8px;
    display: block;

    &:hover {
      color: $red;
    }
  }

  .dd-tags li {
    margin: 0 20px 8px 0;

    @media screen and (max-width: $tablet) {
      margin: 5px;
    }
  }
}
//end TAGS
//====================

//---------------//----------------//

//BUTTONS/ICONS
//====================
.dd-btn-degrade {
  font-size: 22px;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  @include degrade('bottom', #d60000, #750101);
  @include border-radius(10px);
  transition: none;
  padding: 3px;

  @media screen and (max-width: $tablet) {
    font-size: 18px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 16px;
  }

  &:hover {
    padding: 0;
    background: $red--6;
    color: $white;
    border-width: 3px;
    border-style: solid;
    border-color: $white;
    @include boxshadow(0px, 0px, 15px, -4px, rgba(0, 0, 0, 0.9));
  }
}

.dd-btn-red {
  background-color: $red;
  color: $white;
  padding: 5px 12px;
  border: 2px solid $red;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    background-color: $white;
    color: $red;
  }
}

.dd-icon {
  background-image: $sprite;
  background-repeat: no-repeat;
}

.dd-btn-see-all {
  width: 110px;
  color: $gray--2;
  font-style: italic;
  right: 0;
  bottom: 0;
  font-size: 14px;
  text-align: right;
  padding: 4px 12px 4px 0;
  @include degrade('left', $gray--6, $white);

  &:hover {
    color: $red;
  }
}

.dd-btn-see-all-lr {
  padding: 4px 0 4px 12px;
  text-align: left;
  display: inline-block;
  @include degrade('left', $white, $gray--6);
}

.dd-icon-search {
  background-position: -119px top;
  width: 35px;
  height: 34px;
  cursor: pointer;
  transition: none;

  &:hover {
    background-position: -155px top;
  }
}

.dd-icon-search--2 {
  background-position: -28px -87px;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.dd-social-icon {
  width: 21px;
  height: 21px;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  transition: none;
}

.dd-facebook-icon {
  background-position: left top;
  margin-left: 0;

  &:hover {
    background-position: left -22px;
  }
}

.dd-twitter-icon {
  background-position: -22px top;

  &:hover {
    background-position: -22px -22px;
  }
}

.dd-gplus-icon {
  background-position: -44px top;
  margin-right: 0;

  &:hover {
    background-position: -44px -22px;
  }
}

.dd-arrow-down {
  background-position: -66px top;
  width: 8px;
  height: 4px;
  right: 0;
  position: absolute;
  top: 50%;
  margin-top: -(4px/2);
}

.dd-icon-video {
  background-position: -76px top;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 50%;
  margin-top: -(42px/2);
  left: 50%;
  margin-left: -(42px/2);
}

.dd-icon-video-custom {
  background-position: -61px -43px;
  width: 72px;
  height: 72px;
  position: absolute;
  top: 50%;
  margin-top: -(72px/2);
  left: 50%;
  margin-left: -(72px/2);
}

.dd-btn-download {
  background-color: $gray--12;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 26px;
  border-radius: 19px;

  &:hover {
    background-color: $gray;
  }
}
//end BUTTONS/ICONS
//====================

//---------------//----------------//

//BOX INFO AND SHARE
//====================
.dd-post-info {
  font-size: 13px;
  font-style: italic;
  vertical-align: top;
}

.dd-box-share {
  width: 186px;
  font-size: 12px;
  vertical-align: top;

  .dd-input-share-link {
    width: 100%;
    height: 24px;
    border-width: 1px;
    border-style: solid;
    border-color: $gray--6;
    padding: 0 4px;
    margin-top: 7px;
    color: $gray;
    text-align: center;
  }
}

.dd-box-intro {
  width: 100%;
  display: inline-block;

  .dd-post-info {
    width: 70%;
    padding-top: 13px;

    @media screen and (max-width: $tablet){
      width: 100%;
      margin-bottom: 25px;
      padding-top: 0;
    }
  }

  .dd-box-share {
    float: right;

    @media screen and (max-width: $tablet){
      float: left;
    }
  }
}
//end BOX INFO AND SHARE
//====================

//TABLE
//====================
table {
  width: 100%;

  td {
    padding: 14px 20px;
    vertical-align: top;
  }

  td:first-of-type {
    width: 23%;
  }

  a {
    word-break: break-all;
  }
}

.dd-table-style--1 {
  tr:nth-child(odd){
    background-color: $gray--9;
  }

  tr:nth-child(even){
    background-color: $white;
  }
}

.dd-table-style--2 {
  tr:nth-child(odd){
    background-color: $white;
  }

  tr:nth-child(even){
    background-color: $gray--9;
  }
}

.dd-table-thesis {
  border-top: 2px solid $gray--9;

  td:first-of-type {
    width: 20%;
  }
}

.dd-table-ruling {
  margin-bottom: 47px;

  td {
    padding: 14px 46px;
  }

  a {
    color: $gray;
  }

  a:hover {
    color: $red;
  }
}
//end TABLE
//====================

//---------------//----------------//

//BXSLIDER STYLES
//====================
.bx-wrapper .bx-controls-direction {
  .disabled {
    opacity: 0.4;
    display: block !important;
    cursor: default;
  }

  .bx-prev {
    left: 0;
  }

  .bx-next {
    right: 0;
  }

   a {
    background-image: $sprite;
    background-repeat: no-repeat;
    z-index: 9000;
  }
}

.dd-bx-style--1 {
  .bx-wrapper .bx-controls-direction a {
    width: 22px;
    height: 41px;
    margin-top: -20px;
  }

  .bx-prev,
  .bx-prev:hover,
  .bx-prev:focus {
    background-position: 0 -45px;
  }

  .bx-next,
  .bx-next:hover,
  .bx-next:focus {
    background-position: -23px -45px;
  }
}

.dd-bx-style--2 {
  .bx-wrapper .bx-controls-direction a {
    width: 13px;
    height: 28px;
    margin-top: -14px;
  }

  .bx-prev,
  .bx-prev:hover,
  .bx-prev:focus {
    background-position: 0 -87px;
  }

  .bx-next,
  .bx-next:hover,
  .bx-next:focus {
    background-position: -14px -87px;
  }
}

.dd-wrap-slider-expo {
  .bx-viewport {
    max-width: 600px;
    width: calc( 100% - 62px ) !important;
    width: -webkit-calc( 100% - 62px ) !important;
    width: -moz-calc( 100% - 62px ) !important;
    border-bottom-width: 4px;
    border-bottom-color: $red;
    border-bottom-style: solid;
  }
}

.dd-wrap-thumb-slider-expo {
  .bx-viewport {
    max-width: 600px;
    width: calc( 100% - 62px ) !important;
    width: -webkit-calc( 100% - 62px ) !important;
    width: -moz-calc( 100% - 62px ) !important;
  }

  .bx-prev {
    left: 10px;
  }

  .bx-next {
    right: 10px;
  }
}

.dd-wrap-slider-know {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: $red;
  padding-top: 54px;

  .bx-viewport {
    max-width: 923px;
    width: calc( 100% - 80px ) !important;
    width: -webkit-calc( 100% - 80px ) !important;
    width: -moz-calc( 100% - 80px ) !important;
  }

  .bx-prev {
    left: 20px;
  }

  .bx-next {
    right: 20px;
  }
}

.dd-wrap-thumb-slider-milestones {
  margin-bottom: 20px;

  .bx-viewport {
    max-width: 915px;
    width: calc( 100% - 41px ) !important;
    width: -webkit-calc( 100% - 41px ) !important;
    width: -moz-calc( 100% - 41px ) !important;
    padding-bottom:18px;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: $red;
  }

  .bx-wrapper .bx-controls-direction a {
    margin-top: -26px;
  }
}

.dd-wrap-slider-milestones {
  margin-bottom: 55px;

  .bx-viewport {
    max-width: 700px;
    width: calc( 100% - 55px ) !important;
    width: -webkit-calc( 100% - 55px ) !important;
    width: -moz-calc( 100% - 55px ) !important;
  }

  .bx-prev {
    left: 21px;

    @media screen and (max-width: $large) {
      left: 0;
    }
  }

  .bx-next {
    right: 21px;

    @media screen and (max-width: $large) {
      right: 0;
    }
  }
}

.dd-alphabet {
  .bx-wrapper {
    padding: 0 35px;
  }
}

.dd-wrap-slider-resolutions {
  max-width: 480px;

  .bx-wrapper {
    padding: 0 40px;
  }

  .bx-viewport {
    border-bottom-width: 4px;
    border-bottom-color: $red;
    border-bottom-style: solid;
  }
}


.dd-resolution-slider {
  height: 100%;
  float: left;
}

.dd-resolution-slider li,
.dd-resolution-slider li article{
  height: 100%;
}

.dd-thumb-slide,
.dd-default-slider,
.dd-resolution-slider,
.dd-logo-list {
  visibility: hidden;
}

//BXSLIDER STYLES
//====================

//---------------//----------------//

//STYLES LISTS
//====================
.dd-list-style-1 {
  width: 100%;
  display: inline-block;

  li {
    border-top-width: 1px;
    border-top-color: $gray--8;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $gray--8;
    border-bottom-style: solid;
    margin-top: -1px;
    padding: 32px 18px;
  }
}

.dd-list-news-sidebar li {
  border-bottom-width: 1px;
  border-bottom-color: $red;
  border-bottom-style: solid;
  padding-bottom: 37px;
  padding-top: 20px;
  display: inline-block;


  &:last-child{
    border-bottom: 0;
  }

  .dd-thumb {
    width: 70px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

.dd-cloud-tags {
  text-align: justify;

  li {
    margin-right: 12px;
    margin-bottom: 3px;
  }
  a {
    font-family: $open-sans-semi-bold;
    font-size: 15px;
    color: $gray--7;

    &:hover {
      color: $red;
    }
  }

  .dd-size--1 {font-size: 13px;}
  .dd-size--2 {font-size: 14px;}
  .dd-size--3 {font-size: 16px;}
  .dd-size--4 {font-size: 17px;}
  .dd-size--5 {font-size: 20px;}
  .dd-size--6 {font-size: 23px;}
  .dd-size--7 {font-size: 24px;}
  .dd-size--8 {font-size: 28px;}
}

.dd-other-resosultions-list {
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    left: 29px;
    top: 8px;
    width: 1px;
    @include calc('height', '100% - 13px');
    background-color: $red;
  }

  li {
    margin-bottom: 10px;
  }

  article {
    position: relative;
    padding-left: 15px;
  }

  li article:before {
    content: "•";
    color: $red;
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 30px;
    margin-top: -18px;
  }

  a {
    text-transform: uppercase;
    font-size: 13px;
  }
}

.dd-videos-list li {
  @include calc('width', '25% - 30px');
  margin: 0 40px 78px 0;
  vertical-align: top;

  @media screen and (max-width: $tablet) {
    @include calc('width', '50% - 20px');

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    margin: 0 0 45px 0;
  }

  &:nth-child(4n+4) {
    margin-right: 0;
  }
}

.dd-production-list li {
  width: 33.333%;
  vertical-align: top;
  padding: 0 22px;
  margin-bottom: 70px;

  @media screen and (max-width: $large) {
    width: 50%;
  }

  @media screen and (max-width: $tablet) {
    width: 100%;
    padding: 0;
  }

  article {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: $red;
    padding-top: 10px;
  }

  .dd-calc-info {
    @include calc('width', '100% - 100px');
    padding-left: 10px;
  }

  .dd-title-list {
    line-height: 24px;
  }
}

.dd-btns-list li {
  width: 50%;
  padding: 0 55px;
  vertical-align: top;
  margin-bottom: 60px;

  @media screen and (max-width: $large){
    padding: 10px;
  }

  @media screen and (max-width: $tablet) {
    width: 100%;
    margin-bottom: 37px;
  }

  @media screen and (max-width: $mobile) {
    padding: 0;
  }

  a {
    width: 100%;
    min-height: 60px;
    line-height: 27px;
  }
}

.dd-know-btn-list {
  width: 100%;
  display: inline-block;
  margin-top: 106px;
}

.dd-blocks-list a {
    font-size: 18px;
    background-color: $gray--9;
    color: $gray--2;
    text-align: center;
    padding: 6px 0;
    min-height: 60px;
    border-width: 4px;
    border-style: solid;
    border-color: $gray--9;
    @include border-radius(10px);

    &:hover {
      background-color: $gray--10;
      @include boxshadow(0px, 0px, 15px, -4px, rgba(0, 0, 0, 0.9));
      border-color: $white;
      color: $gray--11;
    }
}

.dd-blocks-list li {
  width: 25%;
  padding: 15px 21px;
  vertical-align: top;

  @media screen and (max-width: $tablet) {
    width: 50%;
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

.dd-exposition-list {
  li {
    padding: 0 22px;
    margin-bottom: 60px;

    @media screen and (max-width: $mobile) {
      padding: 0;
    }
  }

  .dd-thumb {
    width: 200px;

    @media screen and (max-width: $tablet) {
      width: 25%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .dd-info {
    line-height: 23px;
  }

  .dd-calc-info {
    @include calc('width', '100% - 200px');
    padding-left: 28px;

    @media screen and (max-width: $tablet) {
      width: 75%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      padding-left: 0;
    }
  }

  .dd-description-post {
    margin-bottom: 18px;
  }
}

.dd-news-list {
  margin-bottom: 42px;

  li {
    position: relative;
    border-bottom-width: 2px;
    border-bottom-color: $red--2;
    border-bottom-style: solid;
    border-top-width: 2px;
    border-top-color: $red--2;
    border-top-style: solid;
    padding: 44px 20px 37px 20px;
    margin-top: -2px;
  }

  .dd-thumb {
    width: 130px;
    vertical-align: top;

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .dd-info {
    width: 100%;
    vertical-align: top;
    line-height: 25px;
  }

  .dd-calc-info {
    @include calc('width', '100% - 130px');
    padding-left: 30px;

    @media screen and (max-width: $mobile) {
      width: 100%;
      padding-left: 0;
    }
  }

  .dd-btn-see-all {
    padding: 4px 24px 4px 0px;
  }
}

.dd-related-content-list {
  li {
    padding: 22px 0 40px 0;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .dd-thumb {
    width: 100px;
    vertical-align: top;

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .dd-info {
    width: 100%;
    vertical-align: top;
  }

  .dd-calc-info {
    @include calc('width', '100% - 100px');
    padding-left: 20px;

    @media screen and (max-width: $mobile) {
      width: 100%;
      padding-left: 0;
    }
  }

  .dd-info .dd-title {
    line-height: 21px;
  }
}

.dd-resolutions-list li{
  border-top-color: $red;
  border-bottom-color: $red;
}
//end STYLES LISTS
//====================

//---------------//----------------//

//PAGINATION
//====================
.dd-pagination {
  font-family: $open-sans-bold;
  text-align: center;
  color: $red;

  .pagination {
    border: 0;
    border-color: $red;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: 10px 0;
  }

  li {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
  }

  li.active {
    font-size: 25px;
    margin: 0 8px;
  }

  a {
    padding: 5px;
  }

  a:hover {
    color: $gray;
  }

  li:first-child,
  li:last-child {
    background-color: $gray--9;
    color: $gray--3;
    font-size: 23px;
    line-height: 18px;
    padding: 3px 6px 6px 6px;
  }

  li:first-child a,
  li:last-child a {
    padding: 0;
    color: $gray--3;
  }

  li:first-child a:hover,
  li:last-child a:hover {
    color: $red;
  }

  li:last-child {
    margin-left: 4px;
  }
}

.dd-pagination-resolutions {
  margin-top: -1px;
}

.dd-alphabet {
  text-align: center;
  padding: 11px 0;
  border: 0;
  border-color: $red;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;

  li {
    font-family: $open-sans-semi-bold;
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 18px;
  }

  li:last-child {
    margin-right: 0;
  }

  li.active {
    font-family: $open-sans-bold;
    color: $red;
    font-size: 22px;
  }

  a {
    color: $gray;
  }
}

.dd-pagination-news .pagination{
  border: 0;
}
//End PAGINATION
//====================

//---------------//----------------//

//GENERAL
//====================

//Positions/Spaces
//====================
.dd-isrelative {
  position: relative;
}

.dd-isabsolute {
  position: absolute;
}

.dd-space {
  padding-top: 60px;
}

.dd-isblock {
  display: block;
}

.dd-isinline {
  display: inline-block;
}

.dd-padding-side {
  padding: 0 22px;

  @media screen and (max-width: $tablet) {
    padding: 0;
  }
}

.dd-margin-b-10 {
  margin-bottom: 10px;
}

.dd-margin-b-25 {
  margin-bottom: 25px;
}

.dd-margin-b-30 {
  margin-bottom: 30px;
}

.dd-margin-b-40 {
  margin-bottom: 40px;
}

.dd-margin-b-50 {
  margin-bottom: 60px;
}

.dd-margin-b-60 {
  margin-bottom: 60px;
}

.dd-margin-b-70 {
  margin-bottom: 70px;
}

.dd-margin-b-80 {
  margin-bottom: 80px;
}

.dd-margin-b-100 {
  margin-bottom: 100px;
}

//Text and Titles
//====================
.dd-title-main {
  font-family: $open-sans-bold;
  color: $red;
  font-size: 22px;
  line-height: 35px;
}

.dd-title-section {
  color: $red;
  font-size: 17px;
  text-transform: uppercase;
  border-bottom-width: 2px;
  border-bottom-color: $red;
  border-bottom-style: solid;
  padding-left: 24px;
  margin-bottom: 11px;
  letter-spacing: 1.7px;
}

.dd-title-list {
  color: $gray;
  font-size: 14px;

  &:hover {
    color: $red;
  }
}

.dd-title-page {
  padding-bottom: 6px;
  border-width: 4px;
  padding-left: 20px;
  margin-bottom: 46px;
}

.dd-info {
  font-size: 14px;
  color: $gray;
}

.dd-semi-bold {
  font-family: $open-sans-semi-bold;
}

.dd-bold {
  font-family: $open-sans-bold;
}

.dd-description {
  font-family: $open-sans-bold;
  margin-bottom: 40px;
  font-style: italic;
  font-size: 14px;
}

.dd-share-text {
  color: $gray--7;
  font-size: 13px;
  margin-right: 19px;
}

.dd-no-content {
  font-size: 15px;
  text-transform: uppercase;
  padding: 30px 0;
}

.dd-date-post {
  font-size: 14px;
  color: $gray--3;
}

.dd-text-error {
  padding-top: 125px;
  font-size: 25px;

  @media screen and (max-width: $mobile) {
    font-size: 22px;
  }
}

figcaption span{
  padding: 8px;
  color: $gray;
  display: block;
  text-align: left;
}

.dd-image-credits {
  position: absolute;
  right: 0;
  top: -30px;
  padding: 7px 22px;
  color: $white;
  background-color: $black-opace;
}

//end GENERAL
//====================

//---------------//----------------//

//CK CONTENT
//====================
.dd-ck-content {
  width: 100%;
  line-height: 24px;
  font-size: 14px;
  display: inline-block;

  p {
    margin-bottom: 24px;
  }

  b, strong {
    font-family: $open-sans-bold;
  }

  // figure {
  //   display: table;
  //   margin: 20px
  // }
  //
  // figcaption {
  //   display: table-caption;
  //   caption-side: bottom;
  //   position: relative;
  // }

  figcaption span{
    line-height: 16px;
  }

  a.img {
    max-width: 100%;
    height: auto !important;
  }

  a.img img{
    height: inherit !important;
  }

  .issuuembed {
    width: 100%;
    max-width: 700px;
    height: 60vw;
    margin: 35px auto;

    @media screen and (min-width: $tablet + 1) {
      height: 460px;
    }
  }

  ul, ol {
    list-style-position: inside;
    margin-left: 10px;
  }

  table {
    thead tr{
      background-color: $white !important;
    }

    thead th {
      padding: 14px 20px;
    }

    tr:nth-child(odd){
      background-color: $gray--9;
    }

    tr:nth-child(even){
      background-color: $white;
    }
  }

  pre {
    background-color: $gray--9;
    padding: .5em;
    border: 1px solid $gray--12;
    display: inline-block;
    margin: 1em 0;
    width: 100%;
    overflow: auto;
    word-wrap: break-word !important;
    white-space: pre-line !important;
  }

  code, kbd, tt, var {
    background-color: $gray--9;
    border: 1px solid $gray--12;
  }

  blockquote > p:before, q:before {
    content: '\201C';
    font-size: 3em;
    padding-right: .2em;
    line-height: .3em;
    vertical-align: bottom;
    color: $gray--7;
  }
}
//end CK CONTENT
//====================

.dd-img-ck-left {
  margin: 10px 20px 15px 0;
}

.dd-img-ck-right {
  margin: 10px 0 15px 20px;
}

.dd-img-centered {
  margin: 10px;
}
