@import '_colors';

// Mixins
	@mixin border-radius($radius) {
	  -webkit-border-radius: $radius;
	     -moz-border-radius: $radius;
	      -ms-border-radius: $radius;
	          border-radius: $radius;

		@content;
	}

	@mixin fade {
		-webkit-transition: all 0.2s linear;
			-moz-transition: all 0.2s linear;
				-ms-transition: all 0.2s linear;
					-o-transition: all 0.2s linear;
						transition: all 0.2s linear;
	}

	@mixin any($property, $expression) {
	  -webkit-#{$property}: #{$expression};
	  -moz-#{$property}: #{$expression};
	  -ms-#{$property}: #{$expression};
		-o-#{$property}: #{$expression};
		#{$property}: #{$expression};
	}

	@mixin calc($property, $expression) {
		#{$property}: calc( #{$expression} );
    #{$property}: -webkit-calc( #{$expression} );
    #{$property}: -moz-calc( #{$expression} );
	}

	@mixin placeholder($property , $expression) {
		#{$property}: #{$expression} !important;

    &::-webkit-input-placeholder {
        #{$property}: #{$expression};
    }

    &:-moz-placeholder {
        #{$property}: #{$expression};
    }

    &::-moz-placeholder {
        #{$property}: #{$expression};
    }

    &:-ms-input-placeholder {
        #{$property}: #{$expression};
    }
	}

	@mixin textshadow($left : 2px, $top : 2px, $degrade : 9px, $color : $preto) {
		-webkit-text-shadow: $left $top $degrade $color;
		  -moz-text-shadow: $left $top $degrade $color;
		    text-shadow: $left $top $degrade $color;
	}

	@mixin boxshadow($hoztl, $vertl, $fade, $size, $color){
		-webkit-box-shadow: $hoztl $vertl $fade $size $color; /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
			box-shadow: $hoztl $vertl $fade $size $color; /* Chrome 6+, Firefox 4+, IE 9+, iOS 5+, Opera 10.50+ */
	}

	@mixin degrade($type, $color1, $color2) {
		background: #{$color1}; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(#{$type}, #{$color1}, #{$color2}); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(#{$type}, #{$color1}, #{$color2}); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(#{$type}, #{$color1}, #{$color2}); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#{to $type}, #{$color1}, #{$color2});
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}', GradientType=0 );
	}
// END Mixins
