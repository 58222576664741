// Colors

  // default
  $black: #000;
  $black-opace: rgba($black, .65);

  $footer-bg: #AE1613;

  $transparent: transparent;

  $white: #fff;

  $gray: #4D4D4D;
  $gray--2: #808080;
  $gray--3: #666666;
  $gray--4: #eaeaea;
  $gray--5: #CCC;
  $gray--6: #e6e6e6;
  $gray--7: #999999;
  $gray--8: #d9d9d9;
  $gray--9: #e5e5e5;
  $gray--10: #b2b2b2;
  $gray--11: #343434;
  $gray--12: #B3B3B3;
  $gray--13: #EEE;
  $red: #C71B1B;
  $red--2: #df9397;
  $red--3: #a70c0c;
  $red--4: #730000;
  $red--5: #D80000;
  $red--6: #d71f27;
  $red--7: #580005;

  // main
  $main: $black;
  $main-link: $red;
  $main-link-hover: $black;


// End Colors
