// Fonts
	$font-path: '/build/assets/fonts';
	$font: Arial, sans-serif;

	//Imports
		@font-face {
	    font-family: 'open_sansregular';
	    src: url('#{$font-path}/OpenSans-Regular-webfont.eot');
	    src: url('#{$font-path}/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
	         url('#{$font-path}/OpenSans-Regular-webfont.woff') format('woff'),
	         url('#{$font-path}/OpenSans-Regular-webfont.ttf') format('truetype'),
	         url('#{$font-path}/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
	    font-weight: normal;
	    font-style: normal;
		}

		@font-face {
	    font-family: 'open_sansbold';
	    src: url('#{$font-path}/OpenSans-Bold-webfont.eot');
	    src: url('#{$font-path}/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
	         url('#{$font-path}/OpenSans-Bold-webfont.woff') format('woff'),
	         url('#{$font-path}/OpenSans-Bold-webfont.ttf') format('truetype'),
	         url('#{$font-path}/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
	    font-weight: normal;
	    font-style: normal;
		}

		@font-face {
		    font-family: 'open_sanssemibold';
		    src: url('#{$font-path}/OpenSans-Semibold-webfont.eot');
		    src: url('#{$font-path}/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
		         url('#{$font-path}/OpenSans-Semibold-webfont.woff') format('woff'),
		         url('#{$font-path}/OpenSans-Semibold-webfont.ttf') format('truetype'),
		         url('#{$font-path}/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
		    font-weight: normal;
		    font-style: normal;
		}

	//END Imports

	$open-sans-regular: 'open_sansregular';
	$open-sans-bold: 'open_sansbold';
	$open-sans-semi-bold: 'open_sanssemibold';
	$font: $open-sans-regular, sans-serif;
// END Fonts
