// Boxes
	$mobile: 480px;
	$tablet: 768px;
	$large: 1000px;
	$all: 90%;

	// customized
	$fif-hund: 500px;
// END Boxes

	$vpath: '/build/assets/images';

	$sprite: url('#{$vpath}/site/sprite.png');

// Elements
	$footer-height: 170px;
// END Elements
